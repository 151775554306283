@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .card {
        background-color: theme('colors.white');
        border-radius: theme('borderRadius.lg');
        box-shadow: theme('boxShadow.md');
    }
}